<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
//import router from "@/router/index.js";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Recherche de trajet",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Recherche de trajet",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Recherche de trajet",
          active: true,
        },
      ],
      thenDay: "",
      id: "",
      totalRows: 0,
      totalRowsDisable: 0,
      currentPage: 1,
      perPage: 500,
      pageOptions: [500, 1000, 2000],
      filter: null,
      filterOn: [],
      alerteData: [],
      alerteDataDes: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "itinéraire", label: "Trajet" },
        { key: "depart", sortable: true, label: "Date départ" },
        { key: "user", label: "Utilisateur" },
        { key: "tel", sortable: true, label: "Téléphone" },
        { key: "date", sortable: true, label: "Date recherche" },
        { key: "action", label: "Actions" },
      ],

      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDatTrajetRechercher: [],
      newStart: false,
      showdisable: false,
      parBon: 500,
      history:{
        id:null,
        user:null,
      },
      deleteModal : false,
    };
  },

  async mounted() {
    this.init();
  },

  methods: {
    async init() {
      const alerteTrajets = await apiRequest(
        "GET",
        "admin/recherches",
        undefined,
        false
      );

      if (alerteTrajets && alerteTrajets.data) {
        console.log("les données du trajet", alerteTrajets.data)
      
        this.totalValue = alerteTrajets.data.total;
        const alerteTrajetsTable = alerteTrajets.data.rechercheFound.map(
          (recherche) => {
            var usersData = []
            usersData.push({id:recherche.user.id, user: recherche.user.firstname + " " + recherche.user.lastname,})
            
            return {
              id: recherche.id,
              itinéraire:
                recherche.lieuDepart + " " + "↦" + " " + recherche.lieuArrivee,
              users:usersData,
              tel:recherche.user!=null?
              
                 recherche.user.indicatifTel + recherche.user.tel
                  : "",
              date: new Date(recherche.createdAt).toLocaleString("fr-FR"),
              depart: new Date(recherche.dateHeure).toLocaleDateString("fr-FR"),
            };
          }
        );

        console.log("alerteTrajetsTable",alerteTrajetsTable)

        this.alerteData = alerteTrajetsTable;
        //this.id = recherche.id;

        this.totalRows = this.alerteData.length;
      }
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;

      this.$v.$touch();
    },

    tooltipForm() {
      this.submitform = true;
      this.formSubmit();
      this.$v.$touch();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async deleteOneHistory(){
      const response = await apiRequest('DELETE','admin/delete/recherches-recherche',{id:this.history.id})
      if (response && response.status == 200){
        this.deleteModal = false
        this.$toast.success("Opération réussie")
        await this.init()
      }


    },
    async deleteAllHistory(){
      const response = await apiRequest('DELETE','admin/delete/recherches-recherche',{user: this.history.user})
      if (response && response.status == 200){
        this.deleteModal = false
        this.$toast.success("Opération réussie")
        await this.init()
      }

    },
    deleteModalOpener(history){
      console.log("history",history)

      this.history.id = history.id
      this.history.user = history.users[0].id

      this.deleteModal =true

      
    },
    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    goToDetail(row) {
      console.log("row:", row);
    },
    desactivity(row) {
      this.showdisable = true;
      this.rowCall = row.id;
    },
    async desactive() {
      this.showdisable = false;
      const returnAddSucess = await apiRequest(
        "POST",
        "admin/alerte-active",
        {
          id: this.rowCall,
          active: false,
        },
        false
      );
      if (returnAddSucess && returnAddSucess.data) {
        this.init();


        this.$toast.success("Opération effectuée avec succès !!!", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
      }
    },

    /** Paginaiton  */

    async actif(page, limit) {
      //Chargement des données
      console.log("début ",page);
      console.log("fin ",limit);
      const data = await apiRequest(
        "GET",
        "admin/recherches?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      if (data && data.data) {
        console.log(data.data)
        const alerteTrajetsTableData = data.data.rechercheFound.map((recherche) => {
          var usersData = []
            usersData.push({id:recherche.user.id, user: recherche.user.firstname + " " + recherche.user.lastname,})

            console.log("user data",usersData)
          return {
            id: recherche.id,
            itinéraire:
              recherche.lieuDepart + " " + "↦" + " " + recherche.lieuArrivee,
            users: usersData,
            tel:
              recherche.user && recherche.user.tel != ""
                ? recherche.user.indicatifTel + recherche.user.tel
                : "",
            date: new Date(recherche.createdAt).toLocaleString("fr-FR"),
            depart: new Date(recherche.dateHeure).toLocaleDateString("fr-FR"),
          };
        });
        this.newDatTrajetRechercher = alerteTrajetsTableData;

        this.userChargeLoader = false;
        this.totalRows = this.newDatTrajetRechercher.length;
      }
    },

    decrement() {
      this.userChargeLoader = true;

      this.startValue -= this.parBon;
      this.endValue -= this.parBon;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += this.parBon;
      this.endValue += this.parBon;
    
      this.actif(this.startValue, this.endValue);
      console.log("userChargeLoader", this.userChargeLoader);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - this.parBon;
      this.endValue = this.totalValue;
      console.log("this.startValue", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = this.parBon;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },

    async userSearch() {
      console.log("début ",this.startValue);
      console.log("fin ",this.endValue);
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "GET",
        "admin/recherches-recherche?page="+ this.startValue + "&limit=" + this.endValue+"&mots=" + this.recherche,
        undefined,
        false
      );

      if (dataSearch && dataSearch.data) {
        if (dataSearch && dataSearch.data) {

          console.log("d searchhhhh",  dataSearch.data);


   
        const alerteTrajetsTable = dataSearch.data.map(
          (recherche) => {
            var usersData = []
            usersData.push({id:recherche.user.id, user: recherche.user.firstname + " " + recherche.user.lastname,})

            console.log("dataaaa",usersData);

            return {
              id: recherche.id,
              itinéraire:
                recherche.lieuDepart + " " + "↦" + " " + recherche.lieuArrivee,
              users: usersData,
              tel:
                recherche.user.tel && recherche.user.tel != ""
                  ? recherche.user.indicatifTel + recherche.user.tel
                  : "",
              date: new Date(recherche.createdAt).toLocaleString("fr-FR"),
              depart: new Date(recherche.dateHeure).toLocaleDateString("fr-FR"),
            };
          }
        );

        this.newDatTrajetRechercher = alerteTrajetsTable;
        //this.id = recherche.id;
      
        this.totalRows = this.newDatTrajetRechercher.length;
        this.userChargeLoader = false;
      }
      }
    },

    goToUserDetail(id) {
     //const routeData = router.resolve({name: 'infoUserPage', params:{idUser:id}});
     //router.push(`/user/user_info/${id}`);
     window.open(`/user/user_info/${id}`, '_blank', 'noreferrer');

    // window.open(routeData.href, '_blank');
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="containerDot" v-if="alerteData.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div
            class="d-flex align-items-center otherThing"
            v-if="userChargeLoader === true"
          >
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body">
            <div
              class="row d-flex justify-content-between align-items-center p-2"
            >
              <div>
                <h4 class="card-title mt-4">
                  Total recherches : {{ totalRows }}
                </h4>
              </div>
              <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == 500"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active"
                      >Liste des trajets recherchés</a
                    >
                  </template>
                  <div
                    class="row inputCol d-flex justify-content-between mt-4 mb-2"
                  >
                    <div class="p-3"></div>
                    <!-- Search -->
                    <div class="p-3">
                      <div class="input">
                        <input
                          type="search"
                          id="serchInput"
                          placeholder="Rechercher..."
                          v-model="recherche"
                          @keyup.enter="userSearch"
                        />
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table
                      class="table-centered"
                      :items="
                        newDatTrajetRechercher.length != 0
                          ? newDatTrajetRechercher
                          : alerteData
                      "
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template v-slot:cell(action)="row">
                        <a
                          href="javascript:void(0);"
                          class="text-danger"
                          v-b-tooltip.hover
                          @click="deleteModalOpener(row.item)"
                          title="Supprimer"
                        >
                          <i class="fas fa-times font-size-18"></i>
                        </a>
                      </template>

                      <template v-slot:cell(user)="row">
                        <div v-for="(person, index) in row.item.users" :key="index" >
                              

                      <span style="text-wrap: nowrap" @click="goToUserDetail(person.id) ">
                                {{ person.user }} <i class="fas fa-eye"></i>
                    </span>
                        </div>
                      </template>

                      

                      <template
                        v-slot:cell(tel)="row"
                        style="text-align: center"
                      >
                        <a
                          :href="'https://wa.me/' + row.value"
                          class="text-success"
                          v-b-tooltip.hover
                          title="Envoyer un message whatsapp"
                          target="_blank"
                        >
                          {{ row.value }}
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row justify-content-end">
                    <transition mode="out-in">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <li>
                            <span class="interval"> {{ startValue }}</span> -
                            <span class="interval">{{ endValue }}</span> sur
                            {{ totalValue }}
                          </li>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <li>
                            <button
                              class="myBouton"
                              @click="firstVal()"
                              :disabled="startValue == 1"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Prem"
                            >
                              <ion-icon
                                name="play-skip-back-outline"
                              ></ion-icon>
                            </button>
                            <button
                              type="button"
                              class="myBouton"
                              @click="decrement()"
                              :disabled="endValue == parBon"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Préc"
                            >
                              <ion-icon name="chevron-back-outline"></ion-icon>
                            </button>
                            <button
                              class="myBouton"
                              @click="increment()"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Suiv"
                              :disabled="endValue >= totalValue"
                            >
                              <ion-icon
                                name="chevron-forward-outline"
                              ></ion-icon>
                            </button>
                            <button
                              class="myBouton"
                              @click="incrementToLast()"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Dern"
                              :disabled="endValue >= totalValue"
                            >
                              <ion-icon
                                name="play-skip-forward-outline"
                              ></ion-icon>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </transition>
                  </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showdisable"
      title="Voulez-vous vraiment suspendre cet utilisateur ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showdisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactive"
          >Oui</b-button
        >
      </div>
    </b-modal>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="deleteModal"
      title="Choisissez le type de suppression !"
      title-class="font-18"
      hide-footer
    >
    <div class="d-flex py-3 justify-content-center text-center ">
          <i class="fas fa-times text-danger fa-xl font-32  p-2 border border-danger rounded-circle"></i>
        </div>
      <div class="d-flex justify-content-between mt-3 ">
        
        <b-button variant="danger" @click="deleteAllHistory">Tout l'historique</b-button>
        <b-button variant="success" class=" " @click="deleteOneHistory"
          >Historique actuel</b-button
        >
        <b-button variant="info" class=" mr-4" @click="deleteModal =false"
          >Annuler</b-button>
      </div>
    </b-modal>
  </Layout>
</template>

<style>
thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  top: -50%;

  flex: 60%;

  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}
.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
}
.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: -13%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
} .paging_simple_numbers ul li{
  color:white!important;
}


.paging_simple_numbers ul li button{
  color:#fff!important;
}
</style>
